<template>
    <div>
            <div class="py-2 px-4 rounded rounded-b-none text-white w-full bg-green-500">
                {{item.name}}
            </div>
            <div class="p-4">
                <div class="grid grid-cols-12 gap-4">
                

                    <div class="col-span-12">
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 lg:row-span-6 lg:col-span-4  border border-gray-200">
                                <div class="flex items-center justify-center">
                                    <img :src="item.foto ? default_url + item.foto : default_url + 'default_sensor.png'" class="w-40 h-40 object-cover" alt="">
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                    <div class="grid grid-cols-12">
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">Kode Perangkat</div>
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">{{item.code}}</div>
                                    </div>
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                    <div class="grid grid-cols-12">
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">pH Air</div>
                                    <div class="col-span-6 border border-gray-200 px-4 py-2 flex">

                                        <div class="w-8 h-4 bg-red-600" v-if="item.pH >= 1 && item.pH < 2"></div>
                                        <div class="w-8 h-4 bg-red-500"  v-else-if="item.pH > 2 && item.pH < 3"></div>
                                        <div class="w-8 h-4 bg-yellow-500" v-else-if="item.pH > 3 && item.pH < 4" ></div>
                                        <div class="w-8 h-4 bg-yellow-400" v-else-if="item.pH > 4 && item.pH < 5" ></div>
                                        <div class="w-8 h-4 bg-yellow-300" v-else-if="item.pH > 5 && item.pH < 6"></div>
                                        <div class="w-8 h-4 bg-green-100" v-else-if="item.pH > 6 && item.pH < 7"></div>
                                        <div class="w-8 h-4 bg-green-300" v-else-if="item.pH > 7 && item.pH < 8"></div>
                                        <div class="w-8 h-4 bg-green-400" v-else-if="item.pH > 8 && item.pH < 9"></div>
                                        <div class="w-8 h-4 bg-green-600" v-else-if="item.pH > 9 && item.pH < 10"></div>
                                        <div class="w-8 h-4 bg-green-700" v-else-if="item.pH > 10 && item.pH < 11"></div>
                                        <div class="w-8 h-4 bg-green-800" v-else-if="item.pH > 11 && item.pH < 12"></div>
                                        <div class="w-8 h-4 bg-blue-600" v-else-if="item.pH > 12 && item.pH < 13"></div>
                                        <div class="w-8 h-4 bg-blue-700" v-else-if="item.pH > 13 && item.pH < 14"></div>
                                        <div class="w-8 h-4 bg-blue-800" v-else-if="item.pH > 14"></div>
                                        <div class="ml-2"> {{parseFloat(item.pH).toFixed(2)}}</div>
                                    </div>
                                    </div>
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                <div class="grid grid-cols-12">
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">Tipe Perangkat</div>
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">
                                        Water Quality
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                <div class="grid grid-cols-12">
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">Tipe Air</div>
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">
                                        <span >{{statusPh(item.pH)}}</span>
                                  
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                <div class="grid grid-cols-12">
                                        <div class="col-span-6 border border-gray-200 px-4 py-2">Update Terakhir</div>
                                <div class="col-span-6 border border-gray-200 px-4 py-2">
                                    {{parseDate(item.last_update,'YYYY-MM-DD HH:mm')}}
                                </div>
                                </div>  
                            </div>                                            
                        </div>
                    </div>
                </div>
                <div class="float-right p-4">
                    <p class="text-blue-500">
                        Monitor--->

                    </p>
                </div>
                
            </div>
    </div>
</template>
<script>
import globalMixin from '../../../mixin/global'
export default {
    props:['item'],
    data: () => ({
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin]
}
</script>