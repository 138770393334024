<template>
    <div>
            <div class="py-2 px-4 rounded rounded-b-none text-white w-full bg-yellow-700">
                {{item.name}}
            </div>
            <div class="p-4">
                <div class="grid grid-cols-12 gap-4">
                

                    <div class="col-span-12">
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 lg:row-span-6 lg:col-span-4  border border-gray-200">
                                <div class="flex items-center justify-center">
                                    <img :src="item.foto ? default_url + item.foto : default_url + 'default_sensor.png'" class="w-40 h-40 object-cover" alt="">
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                    <div class="grid grid-cols-12">
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">Kode Perangkat</div>
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">{{item.code}}</div>
                                    </div>
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                    <div class="grid grid-cols-12">
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">Rainfall Intensity</div>
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">{{parseFloat(item.precipitation).toFixed(2)}} mm</div>
                                    </div>
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                <div class="grid grid-cols-12">
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">Tipe Perangkat</div>
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">
                                        Weather Station
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                <div class="grid grid-cols-12">
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">Cuaca</div>
                                    <div class="col-span-6 border border-gray-200 px-4 py-2">
                                        Hujan
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-span-12 lg:col-span-8">
                                <div class="grid grid-cols-12">
                                        <div class="col-span-6 border border-gray-200 px-4 py-2">Update Terakhir</div>
                                <div class="col-span-6 border border-gray-200 px-4 py-2">
                                    {{parseDate(item.last_update,'YYYY-MM-DD HH:mm')}}
                                </div>
                                </div>  
                            </div>                                            
                        </div>
                    </div>
                </div>
                <div class="float-right p-4">
                    <p class="text-blue-500">
                        Monitor--->

                    </p>
                </div>
                
            </div>
    </div>
</template>
<script>
import globalMixin from '../../../mixin/global'
export default {
    props:['item'],
    data: () => ({
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin]
}
</script>