<template>
    <div>
        <deviceComponent v-if="user.customer_id || customer"/>
        <customerComponent v-else-if="!customer"/>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import customerComponent from './customer.vue'
import deviceComponent from './device.vue'
export default {
    computed:{
          ...mapGetters({
            customer:'device/customer',
            user:'auth/user'
        })
    },
    created() {
    },
    components:{customerComponent,deviceComponent}
}
</script>